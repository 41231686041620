.listitem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.titlewrapper {
  display: flex;
  align-items: center;
  padding: 25px 0px 10px 0px;
}
.title {
  font-weight: bold;
  padding: 25px 0px 0px 25px;
}
.search-field {
  width: 95%;
  padding: 10px;
}

.listItemWrapper {
  display: flex;
  flex-direction: column;
}
.mainWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.stationstring .MuiListItemText-secondary {
  font-size: 11px !important;
}

.timeCell {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.timeCellContent {
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
}
.headCell {
  background-color: darkgrey !important;
}
.headerWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  align-items: baseline;
}

.hero {
  display: flex;
  flex-direction: column;
  background-image: url("./../img/homeimg.jpg");
  height: 90vh;
  align-self: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  align-items: center;
  justify-content: flex-start;
}

.text {
  font-size: 1.4rem;
  line-height: 6;
  text-align: center;
  color: white;
  margin-top: 150px;
}

.listitemTextWrapper {
  display: flex;
  flex-direction: row;
}
.stationName {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: baseline;
}

.modalContent {
  background-color: rgb(255, 255, 255, 0.1) !important;
  color: white;
  padding: 10px;
}

.connectionsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modalHeaderWrapper {
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
}

.priceInfoText {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
}

.priceInfoWhere {
  font-size: 12px;
  margin-top: 10px;
}
.priceValue {
  display: flex;
  flex-direction: row;
  justify-content: end;
  font-size: x-large;
}
.stationlistInfoBtn {
  background-color: #011234 !important;
  color: white !important;
}
.stationListInfoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.stationListBtnWrapper {
  display: flex;
  flex-direction: row;

  gap: 10px;
}

.dayInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
}
.allgInfo {
  display: flex;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  justify-content: center;
}
.linerDesc {
  display: flex;
  padding: 5px;
  font-size: 12px;
}
.rectangleHeader {
  border: 1px solid black; /* Adds a border around the rectangle */
  margin-right: 2px;
  padding: 4px;
}
.rectangle {
  border: 1px solid black; /* Adds a border around the rectangle */
  margin-right: 2px;
  padding-left: 1px;
  padding-right: 1px;
}

.noDataIconTextWapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noDataIconTextWapper p {
  font-size: large;
  font-weight: bold;
}
